@import url("https://fonts.googleapis.com/css2?family=Fraunces:wght@300&family=Lora&display=swap");
.App {
  text-align: center;
  font-family: "Lora", serif;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}

.jumbotron {
  background-color: rgba(0, 0, 0, 0.5) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./Assets//home_banner.jpg");
  background-size: cover;
}

.header_info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 767px) {
  .header_info {
    align-items: initial;
  }
}

.col-6 {
  font-weight: 100;
}

.col-6 .h2 {
  font-weight: 600;
}

.carousel-inner {
  height: 60vh;
}
