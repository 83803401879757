@media screen and (max-width: 768px) {
  .carousel-inner img {
    height: 30vh;
    object-fit: fill;
    object-position: center;
  }

  .carousel-inner {
    height: 30vh;
  }
}

@media screen and (min-width: 768px) {
  .carousel-inner img {
    height: 60vh;
    object-fit: cover;
  }
}
