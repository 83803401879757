@import url(https://fonts.googleapis.com/css2?family=Fraunces:wght@300&family=Lora&display=swap);
* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: "Lora", serif;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}

.jumbotron {
  background-color: rgba(0, 0, 0, 0.5) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/home_banner.b18a1a70.jpg);
  background-size: cover;
}

.header_info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 767px) {
  .header_info {
    align-items: initial;
  }
}

.col-6 {
  font-weight: 100;
}

.col-6 .h2 {
  font-weight: 600;
}

.carousel-inner {
  height: 60vh;
}

@media screen and (max-width: 768px) {
  .carousel-inner img {
    height: 30vh;
    object-fit: fill;
    object-position: center;
  }

  .carousel-inner {
    height: 30vh;
  }
}

@media screen and (min-width: 768px) {
  .carousel-inner img {
    height: 60vh;
    object-fit: cover;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h4 {
  line-height: 2rem;
}
@media screen and (min-width: 769px) {
  .col-4 {
    max-width: 500px;
  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .h4 {
    line-height: 2.5rem;
  }
}

/* .col-6 {
  height: 15vh;
} */

