.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h4 {
  line-height: 2rem;
}
@media screen and (min-width: 769px) {
  .col-4 {
    max-width: 500px;
  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .h4 {
    line-height: 2.5rem;
  }
}
